import request from '@/utils/request'

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'stock/warning',
      params
    })
  },
  purchase(data) {
    return request({
      method: 'post',
      url: 'stock/warning/purchase',
      data
    })
  }

}
