var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('van-swipe-cell', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function fn() {
        return [_c('van-button', {
          staticStyle: {
            "height": "100%"
          },
          attrs: {
            "square": "",
            "type": "warning",
            "text": "采购"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('purchase');
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "goods-info-container"
  }, [_c('div', {
    staticClass: "goods-info-container__left"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.product.image
    }
  })], 1), _c('div', {
    staticClass: "goods-info-container__right"
  }, [_c('div', {
    staticClass: "goods-name"
  }, [_vm._v(" " + _vm._s(_vm.goods.product.name) + " ")]), _c('van-row', {
    staticClass: "size"
  }, [_c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('span', [_vm._v("规格：1*" + _vm._s(_vm.goods.product.size))])]), _c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('span', {
    staticClass: "storage"
  }, [_vm._v("储位：" + _vm._s(_vm.goods.default_in_warehouse.storage))])])], 1), _c('van-row', {
    staticClass: "stock"
  }, [_c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('span', {
    staticClass: "book-stock"
  }, [_vm._v(" 账面库存： " + _vm._s(_vm.goods.book_stock) + " ")])]), _c('van-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('span', [_vm._v(" 预警值： " + _vm._s(_vm.goods.stock_warning) + " ")])])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }