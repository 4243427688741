<template>
  <div>
    <my-nav-bar
      :title="$route.query.warehouse_name"
      left-text="返回"
      left-arrow
    />
    <div class="search-bar">
      <form action="/">
        <van-search
          v-model="listQuery.goods_name"
          placeholder="请输入商品名称"
          clearable
          @search="handleSearch"
        />
      </form>
    </div>
    <div class="app-main-search-container stock-warning-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-if="goodses.length > 0" class="tips">提示：往左滑动可以加入采购清单</div>
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getList"
      >
        <div v-for="(item, index) in goodses" :key="index" class="stock-warning-goods-container">
          <stock-warning-goods
            :goods="item"
            @purchase="handlePurchase(item)"
          />
        </div>
      </van-list>
    </div>
    <van-dialog
      v-model="showDialog"
      title="加入清单"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1989fa"
      @confirm="handleConfirm"
    >
      <div v-if="goods" class="input-container">
        <van-field
          v-if="goods.product.large_unit"
          v-model="largeVolume"
          label-width="50"
          :label="goods.product.large_unit"
          placeholder="请输入数量"
        />
        <van-field
          v-model="leastVolume"
          :label="goods.product.least_unit"
          label-width="50"
          placeholder="请输入数量"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import stockWarningApi from '@/api/stock_warning'
import StockWarningGoods from './components/goods'
import MyNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'StockWarningDetail',
  components: { StockWarningGoods, MyNavBar },
  data() {
    return {
      goodses: [],
      loading: false,
      finished: false,
      showDialog: false,
      goods: null,
      largeVolume: '',
      leastVolume: '',
      listQuery: {
        page: 0,
        limit: 10,
        warehouse_id: this.$route.query.warehouse_id,
        goods_name: ''
      },
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      stockWarningApi.list(this.listQuery).then(res => {
        this.goodses = this.goodses.concat(res.data.list)
        this.loading = false
        if (res.data.list.length < this.listQuery.limit) { this.finished = true }
        this.showEmpty = this.goodses.length === 0
      })
    },
    handlePurchase(goods) {
      this.goods = goods
      this.largeVolume = ''
      this.leastVolume = ''
      this.showDialog = true
    },
    handleConfirm() {
      const data = {
        goods_id: this.goods.id,
        large_volume: Number(this.largeVolume),
        least_volume: Number(this.leastVolume)
      }
      this.beginLoad()
      stockWarningApi.purchase(data).then(res => {
        this.success(res.msg)
      })
    },
    handleSearch() {
      this.listQuery.page = 0
      this.goodses = []
      this.finished = false
      this.showEmpty = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/styles/color.scss";
  .tips {
    margin-bottom: 10px;
    color: $grayTextColor;
  }
  .stock-warning-container {
    padding: 15px 15px 5px 15px;
  }
  .stock-warning-goods-container {
    margin-bottom: 10px;
  }
  .input-container {
    padding: 10px 15px;
  }
  .search-bar {
      z-index: 1;
      position: fixed;
      top: 46px;
      left: 0;
      right: 0;
    }
</style>
