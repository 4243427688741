<template>
  <van-swipe-cell>
    <template #right>
      <van-button style="height: 100%" square type="warning" text="采购" @click="$emit('purchase')" />
    </template>
    <div class="goods-info-container">
      <div class="goods-info-container__left">
        <van-image :src="goods.product.image" />
      </div>
      <div class="goods-info-container__right">
        <div class="goods-name">
          {{ goods.product.name }}
        </div>
        <van-row class="size">
          <van-col span="12">
            <span>规格：1*{{ goods.product.size }}</span>
          </van-col>
          <van-col span="12">
            <span class="storage">储位：{{ goods.default_in_warehouse.storage }}</span>
          </van-col>
        </van-row>
        <van-row class="stock">
          <van-col span="12">
            <span class="book-stock">
              账面库存：
              {{ goods.book_stock }}
            </span>
          </van-col>
          <!-- <span>
            在途库存：
            {{ goods.transit_stock }}
          </span> -->
          <van-col span="12">
            <span>
              预警值：
              {{ goods.stock_warning }}
            </span>
          </van-col>
        </van-row>
        <!-- <div class="warehouse-stock">
          <span style="margin-right: 10px;">
            仓库库存：
            {{ goods.default_in_warehouse.stock }}
          </span>
          <span>
            库存预警：
            {{ goods.stock_warning }}
          </span>
        </div> -->
        <!-- <div class="real-volume">
          <span>
            预警值：
            {{ goods.book_stock + goods.transit_stock - goods.stock_warning }}
          </span>
        </div> -->
      </div>
    </div>
    <!--<div class="footer">-->
    <!--<van-button block round type="info" text="立即采购" @click="$emit('purchase')" />-->
    <!--</div>-->
  </van-swipe-cell>
</template>

<script>
export default {
  name: 'StockWarningGoods',
  props: {
    goods: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/color.scss';
  .goods-info-container {
    padding: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    &__left {
      width: 80px;
      height: 80px;
      .van-image {
        width: 80px;
        height: 80px;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      height: 80px;
      justify-content: start;
      width: 100%;
      /*.goods-name {*/
        /*margin-bottom: 8px;*/
      /*}*/
      div {
        margin-bottom: 2px;
      }
      .size, .warehouse-stock{
        font-size: 13px;
        color: $grayTextColor;
      }
      .stock {
        font-size: 13px;
        color: $grayTextColor;
      }
      .book-stock {
        margin-right: 10px;
      }
      .real-volume {
        font-size: 13px;
        color: $amountRedColor;
      }
      .storage {
        // margin-left: 10px;
      }
    }
  }
  .footer {
    padding: 15px 50px;
    background-color: #fff;
    .van-button {
      height: 35px;
      &__content {
        height: 35px;
      }
    }
  }
</style>
